// StepsController.js

import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  Typography,
  Paper,
  IconButton,
  Grid,
} from "@mui/material";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getAwsUrl } from "./EnvHelper";
import { useAuth0 } from "@auth0/auth0-react";
import { v4 as uuidv4 } from "uuid";

import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";

// Enums for types and nextAction
const StepTypes = ["input", "selection", "button", "final"];
const NextActions = ["apiCall", "apiCallMultiple", "none"];

const defaultStep = {
  id: "",
  title: "",
  stateKey: "",
  renderConfig: {
    type: "input",
    label: "",
    buttonText: "",
  },
  nextAction: "apiCall",
  prompt: "",
  resultKey: "",
  nextStep: null,
};

function StepsController() {
  const [steps, setSteps] = useState([]);
  const [editingStep, setEditingStep] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const [stepName, setStepName] = useState(""); // For saving/loading steps by name

  useEffect(() => {
    loadSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadSteps = async () => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "read:current_user email profile",
      },
    });

    const url = (await getAwsUrl("REACT_APP_DRAGONSCALES_ENDPOINT")) + "/steps";

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);
        setSteps(data);
        alert("Steps loaded!");
      })
      .catch((error) => {
        console.error("Error loading steps:", error);

        // For now, just load from localStorage
        // const steps = localStorage.getItem("steps_" + stepName);
        // setSteps(JSON.parse(steps));
        // alert("Steps loaded from local storage!");

        alert("Error loading steps!");
      });
  };

  const saveSteps = async () => {
    // Implement API call to save steps by name
    // For now, just save to localStorage
    // localStorage.setItem("steps_" + stepName, JSON.stringify(steps));
    // alert("Steps saved!");

    if (!steps) {
      alert("No steps to save!");
      return;
    }

    if (steps.length === 0) {
      alert("No steps to save!");
      return;
    }

    if (!stepName) {
      alert("Please enter a step name!");
      return;
    }

    if (steps.some((step) => !step.title)) {
      alert("All steps must have a title!");
      return;
    }

    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "read:current_user email profile",
      },
    });

    const url = (await getAwsUrl("REACT_APP_DRAGONSCALES_ENDPOINT")) + "/steps";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        accept: "application/json",
      },
      body: JSON.stringify(steps),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);
        alert("Steps saved!");
      })
      .catch((error) => {
        console.error("Error saving steps:", error);

        localStorage.setItem("steps_" + stepName, JSON.stringify(steps));
        alert("Steps saved locally!");
      });
  };

  const handleAddStep = () => {
    const newStep = {
      ...defaultStep,
      id: uuidv4(),
      title: "New Step",
    };
    setSteps([...steps, newStep]);
  };

  const handleEdit = (id) => {
    const step = steps.find((s) => s.id === id);
    setEditingStep(step);
  };

  const handleDelete = (id) => {
    setSteps(steps.filter((step) => step.id !== id));
    // If the deleted step was being edited, close the editor
    if (editingStep && editingStep.id === id) {
      setEditingStep(null);
    }
  };

  const handleSaveStep = () => {
    if (steps) {
      setSteps(
        steps.map((step) => (step.id === editingStep.id ? editingStep : step))
      );
      // Keep editingStep open for continuous editing
    }
  };

  const handleReorder = (result) => {
    // Your existing handleReorder implementation
    // For brevity, omitted here
  };

  const handlePreviousStep = () => {
    const currentIndex = steps.findIndex((step) => step.id === editingStep.id);
    if (currentIndex > 0) {
      setEditingStep(steps[currentIndex - 1]);
    }
  };

  const handleNextStep = () => {
    const currentIndex = steps.findIndex((step) => step.id === editingStep.id);
    if (currentIndex < steps.length - 1) {
      setEditingStep(steps[currentIndex + 1]);
    }
  };

  return (
    <Container maxWidth="lg">
      {/* Top Controls */}
      <Box sx={{ mt: 2, mb: 2 }}>
        <TextField
          label="Step Name"
          value={stepName}
          onChange={(e) => setStepName(e.target.value)}
          sx={{ mr: 2 }}
        />
        <Button variant="contained" onClick={loadSteps} sx={{ mr: 2 }}>
          Load Steps
        </Button>
        <Button variant="contained" onClick={saveSteps} sx={{ mr: 2 }}>
          Save Steps
        </Button>
        <Button variant="contained" onClick={handleAddStep}>
          Add Step
        </Button>
      </Box>

      {/* Steps List and Editor Side by Side */}
      <Grid container spacing={2}>
        {/* Steps List */}
        <Grid item xs={12} md={4}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Steps List
          </Typography>
          <DragDropContext onDragEnd={handleReorder}>
            <Droppable droppableId="steps">
              {(provided) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  sx={{ width: "100%" }}
                >
                  {steps &&
                    steps.map((step, index) => (
                      <Draggable
                        key={step.id}
                        draggableId={step.id}
                        index={index}
                      >
                        {(provided) => (
                          <Paper
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            sx={{
                              p: 2,
                              mb: 1,
                              cursor: "pointer",
                              backgroundColor:
                                editingStep && editingStep.id === step.id
                                  ? "#e3f2fd"
                                  : "inherit",
                            }}
                            onClick={() => handleEdit(step.id)}
                          >
                            <Box
                              {...provided.dragHandleProps}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Typography variant="h6">
                                {index + 1}. {step.title}
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography variant="body2">
                                Type: {step.renderConfig.type}
                              </Typography>
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEdit(step.id);
                                }}
                                sx={{ ml: "auto" }}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDelete(step.id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </Paper>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>

        {/* Editor */}
        <Grid item xs={12} md={8}>
          {editingStep ? (
            <Paper sx={{ p: 2 }}>
              <Typography variant="h5" sx={{ mb: 2 }}>
                Edit Step
              </Typography>
              <TextField
                label="Title"
                value={editingStep.title}
                onChange={(e) =>
                  setEditingStep({ ...editingStep, title: e.target.value })
                }
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="State Key"
                value={editingStep.stateKey}
                onChange={(e) =>
                  setEditingStep({ ...editingStep, stateKey: e.target.value })
                }
                fullWidth
                sx={{ mb: 2 }}
              />
              <Select
                value={editingStep.renderConfig.type}
                onChange={(e) =>
                  setEditingStep({
                    ...editingStep,
                    renderConfig: {
                      ...editingStep.renderConfig,
                      type: e.target.value,
                    },
                  })
                }
                fullWidth
                sx={{ mb: 2 }}
              >
                {StepTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                label="Label"
                value={editingStep.renderConfig.label || ""}
                onChange={(e) =>
                  setEditingStep({
                    ...editingStep,
                    renderConfig: {
                      ...editingStep.renderConfig,
                      label: e.target.value,
                    },
                  })
                }
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="Button Text"
                value={editingStep.renderConfig.buttonText || ""}
                onChange={(e) =>
                  setEditingStep({
                    ...editingStep,
                    renderConfig: {
                      ...editingStep.renderConfig,
                      buttonText: e.target.value,
                    },
                  })
                }
                fullWidth
                sx={{ mb: 2 }}
              />
              <Select
                value={editingStep.nextAction || "none"}
                onChange={(e) =>
                  setEditingStep({ ...editingStep, nextAction: e.target.value })
                }
                fullWidth
                sx={{ mb: 2 }}
              >
                {NextActions.map((action) => (
                  <MenuItem key={action} value={action}>
                    {action}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                label="Prompt"
                value={editingStep.prompt}
                onChange={(e) =>
                  setEditingStep({ ...editingStep, prompt: e.target.value })
                }
                multiline
                rows={6}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="Result Key"
                value={editingStep.resultKey}
                onChange={(e) =>
                  setEditingStep({ ...editingStep, resultKey: e.target.value })
                }
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="Next Step"
                value={editingStep.nextStep || ""}
                onChange={(e) =>
                  setEditingStep({ ...editingStep, nextStep: e.target.value })
                }
                fullWidth
                sx={{ mb: 2 }}
              />
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="contained"
                  onClick={handleSaveStep}
                  sx={{ mr: 2 }}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setEditingStep(null)}
                  sx={{ mr: 2 }}
                >
                  Close
                </Button>
                <Button
                  variant="contained"
                  onClick={handlePreviousStep}
                  disabled={
                    steps.findIndex((step) => step.id === editingStep.id) === 0
                  }
                  sx={{ mr: 2 }}
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  onClick={handleNextStep}
                  disabled={
                    steps.findIndex((step) => step.id === editingStep.id) ===
                    steps.length - 1
                  }
                >
                  Next
                </Button>
              </Box>
            </Paper>
          ) : (
            <Typography variant="h6" sx={{ mt: 2 }}>
              Select a step to edit
            </Typography>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default StepsController;
