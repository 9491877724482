const steps = [
  // Phase 1: Idea Generation
  {
    id: 0,
    title: "Input Adventure Theme",
    stateKey: "userInput",
    renderConfig: {
      type: "input",
      label: "What would you like the adventure to be about?",
      buttonText: "Generate Adventure Ideas",
    },
    nextAction: "apiCall",
    prompt: (data) => `
You are an expert RPG adventure designer.
Generate 3 unique adventure ideas based on the user's input.

**User Input:** "${data.userInput}" 

**Constraints:**

- Each idea must include a unique **Title**, **Theme**, and **Setting**.
- Titles should be engaging and relevant.
- Themes should be diverse and captivating.
- Settings should be imaginative and detailed.
- Do not include any disallowed content.

**Instructions:**

- Provide the output in valid JSON format.
- Structure the JSON as an array of objects, each containing "Title", "Theme", and "Setting" keys.

**Example Output Format:**

[
  {
    "Title": "...",
    "Theme": "...",
    "Setting": "..."
  },
  ...
]

Now, generate the 3 adventure ideas.
`,
    resultKey: "adventureIdeas",
    nextStep: 1,
  },
  {
    id: 1,
    title: "Select Adventure Idea",
    stateKey: "selectedIdea",
    renderConfig: {
      type: "selection",
      dataKey: "adventureIdeas",
      itemTitleKey: "Title",
      itemSubtitleKey: "Theme",
      itemDescriptionKey: "Setting",
    },
    nextStep: 2,
  },
  // Phase 2: Antagonist Development
  {
    id: 2,
    title: "Generate Antagonists",
    stateKey: "antagonists",
    renderConfig: {
      type: "button",
      buttonText: "Generate Antagonists",
    },
    nextAction: "apiCall",
    prompt: (data) => `
You are an expert RPG adventure designer specializing in creating compelling antagonists that enhance the narrative depth of the adventure.

**Adventure Context:**
- **Original User Input:** "${data.userInput}"
- **Selected Adventure Idea:**
  - **Title:** "${data.selectedIdea.Title}"
  - **Theme:** "${data.selectedIdea.Theme}"
  - **Setting:** "${data.selectedIdea.Setting}"

**Constraints:**

- Each antagonist must align with the adventure's theme and setting.
- Provide for each antagonist:
  - **Name**
  - **BasicDescription**: Physical appearance and characteristics.
  - **InitialMotivation**: What drives them at the start of the adventure.
  - **RoleInAdventure**: Their role within the story.
  - **AbilitiesAndResources**: Any special skills, powers, or assets.

- Ensure each antagonist is unique and memorable.
- Do not include any disallowed content.

**Instructions:**

- Provide the output in valid JSON format.
- Structure the JSON as an array of objects with keys: "Name", "BasicDescription", "InitialMotivation", "RoleInAdventure", and "AbilitiesAndResources".

---

Now, generate the antagonists.
`,
    resultKey: "antagonists",
    nextStep: 3,
  },
  {
    id: 3,
    title: "Select Antagonist",
    stateKey: "selectedAntagonist",
    renderConfig: {
      type: "selection",
      dataKey: "antagonists",
      itemTitleKey: "Name",
      itemSubtitleKey: "BasicDescription",
      itemDescriptionKey: "RoleInAdventure",
    },
    nextStep: 4,
  },
  // Phase 3:
  {
    id: 4,
    title: "Generate Next",
    stateKey: "narrativeSummary",
    renderConfig: {
      type: "button",
      buttonText: "Generate Next",
    },
    nextAction: "apiCall",
    prompt: (data) => `
    You are an expert RPG adventure designer. You are tasked with developing a narrative overview and key characters for the adventure.

**Adventure Context:**
- **Original User Input:** "${data.userInput}"

**Selected Adventure Idea:**
  - **Title:** "${data.selectedIdea.Title}"
  - **Theme:** "${data.selectedIdea.Theme}"
  - **Setting:** "${data.selectedIdea.Setting}"

**Selected Antagonist:**
  - **Alias**: "NIGHTSHADE"
  - **BasicDescription**: "${data.selectedAntagonist.BasicDescription}"
  - **InitialMotivation**: "${data.selectedAntagonist.InitialMotivation}"
  - **RoleInAdventure**: "${data.selectedAntagonist.RoleInAdventure}"

**Instructions:**

- Create a narrative summary of the adventure, including key characters and plot points.
- Populate the "NarrativeSummary" section with the following details:
  - **Overview**: A brief summary of the adventure's premise and central conflict.
  - **Primer**: An engaging read-aloud introduction to the adventure, setting the tone and inviting players into the story.
  - **Characters**: 4-5 Major characters involved in the adventure, including the selected antagonist.
    - For each character, provide:
      - **Alias**: chosen alias for the character. (Enum, see below)
      - **Role**: Their function in the story.
      - **Connection**: How they relate to the plot or other characters.
  - **Plot**: Outline the adventure's key 4-6 chapters or events.
    - For each chapter, include:
      - **Title**: The chapter's name or description.
      - **Overview**: A summary of the chapter's events and developments.
      - **Type**: Indicate the chapter type. (Enum, see below)
      - **Beginning**: The starting point or setup for the chapter. Ensure a logical flow between chapters.
      - **End**: The resolution or outcome of the chapter. Ensure a logical flow between chapters.

- **Chapter Types:** (Enum)

1. **Exploration**: The party navigates a new environment, uncovering secrets, hidden passages, or important landmarks. This encourages players to describe their actions and ask questions about their surroundings.
2. **Encounter**: A battle sequence where the party engages with enemies or creatures. This tests their tactical skills and coordination as they work together to defeat their opponents.
3. **Challenge**: The party encounters a puzzle that requires problem-solving, such as deciphering a riddle, manipulating mechanisms, or figuring out patterns to unlock a door.
4. **Social**: Engaging in conversations with non-player characters (NPCs), such as negotiating terms, gathering information, or building relationships that can influence future events.
5. **Investigation**: The party searches for clues within a scene to solve a mystery or understand a perplexing situation, encouraging critical thinking and attention to detail.
6. **Shopping**: Scenes where the party buys, sells, or trades items with merchants or vendors, manages resources, and possibly haggles for better prices or rare and unique items.
7. **Downtime**: The party takes a break to rest, heal, and plan their next moves. This can include role-playing character interactions, managing equipment, or pursuing personal goals.

- **Chapter Narrative Arc**
  - The adventure should have a clear narrative arc, building tension and stakes as the story progresses.
  - Ensure a balance of types to engage players and maintain their interest.
  - Use narrative tropes to build a compelling story that resonates with the adventure's theme and setting.
  - Build to a satisfying climax and resolution that ties up loose ends and rewards players for their efforts.

When crafting a plot and organizing it into chapters, begin by defining strong, clear goals for your characters.
This not only propels the narrative forward but also deepens reader engagement.
A well-defined goal helps prevent the storyline from losing focus or readers from questioning its direction.
Use these goals as a foundation for your chapters, drawing on classic story structures that are instantly relatable.

**Example Output Format:**

- Antagonist Alias: "NIGHTSHADE"
- Available Aliases for Example:
CAMERON, AVERY, KYRIE, UNIQUE, KAI, WEST, JUSTICE, LOGAN, ONYX, URIAH
*IMPORTANT*: Use only the provided aliases for characters. Do not create new ones. If an alias is already used, choose another from the list.

{
  "Title": "The Heist of the Arcane Vault",
  "NarrativeSummary": {
    "Overview": "In the bustling metropolis of Eldergate, a notorious thief targets the city's most secure bank to steal a magical necklace rumored to grant immense power. The adventurers must navigate a web of deception, unlock ancient secrets, and confront the mysterious thief named LOGAN before he unleashes the necklace's powers for his own sinister purposes.",
    "Primer": "Attention, adventurers! The city of Eldergate stands on the edge of chaos, and time is of the essence. LOGAN, the elusive master thief, has marked tonight as his moment to strike. His target? The Arcane Vault, home to a legendary necklace of unimaginable power. As the festival's lights dance over Eldergate, shadows creep with malevolent intent.\n\nYou find yourselves at the heart of this unfolding drama, summoned by destiny or perhaps sheer coincidence. Whatever the reason, the call to action is undeniable. Streets buzz with the excited fervor of the festival, yet at its core lies a simmering tension. The vault's defenses stand tall, but LOGAN's cunning knows no bounds.\n\nYour objective is clear: intercept the heist, outmaneuver LOGAN's every move, and safeguard the city's future. Gather your wits, harness your skills, and venture into the catacombs where secrets and challenges await. Allies will emerge, and enemies will lurk in every shadow.\n\nPrepare yourselves for a night of intrigue and danger. The door to adventure creaks open, and the path ahead demands bravery and wit. Onward, into the fray! The fate of Eldergate rests upon your shoulders.",
    "Characters": [
      {
        "Alias": "LOGAN",
        "Role": "The cunning thief and antagonist who seeks the magical necklace for personal gain.",
        "Connection": "Drives the conflict by orchestrating the bank heist and manipulating key players to achieve his goals."
      },
      {
        "Name": "KYRIE",
        "Role": "A skilled illusionist and an ally of the adventurers, offering her expertise in navigating magical traps.",
        "Connection": "Assists the group in locating the hidden components needed to access the vault and counters LOGAN's tricks."
      },
      {
        "Name": "JUSTICE",
        "Role": "The head of Eldergate's city guard, determined to prevent the heist and protect the city's treasures.",
        "Connection": "Initially skeptical of the adventurers but becomes a critical ally once he sees their intentions align."
      },
      {
        "Name": "ONYX",
        "Role": "A renowned historian who holds crucial knowledge about the necklace and its origins.",
        "Connection": "Uncovers historical clues that lead the adventurers to the vault and warns of the necklace's true dangers."
      }
    ],
    "Plot": [
      {
        "Title": "The Gathering Storm",
        "Overview": "Rumors of the impending heist spread through Eldergate, prompting the adventurers to team up with JUSTICE to safeguard the bank. ONYX shares rumors of the necklace's mythical powers.",
        "Type": "Social",
        "Beginning": "Adventurers meet in Eldergate, forming alliances amidst festival excitement.",
        "End": "An agreement is made to thwart LOGAN's heist."
      },
      {
        "Title": "Secrets of the Vault",
        "Overview": "With ONYX's guidance, the group explores the catacombs beneath the city, searching for clues about the vault's defenses and the necklace's hidden past.",
        "Type": "Exploration",
        "Beginning": "Explorers enter the catacombs, discovering clues about the vault's defenses.",
        "End": "They emerge with pathways for safeguarding the vault."
      },
      {
        "Title": "A Game of Shadows",
        "Overview": "KYRIE helps the group set up magical barriers to reinforce the vault's existing defenses. They gather intelligence on LOGAN's plans, identifying his known associates.",
        "Type": "Challenge",
        "Beginning": "Planning and spellcrafting with KYRIE to fortify the vault.",
        "End": "Preparations complete, adventurers await heist night."
      },
      {
        "Title": "The Heist Begins",
        "Overview": "LOGAN launches his heist during Eldergate’s annual festival, utilizing chaos to breach the bank. The adventurers must navigate the turmoil and stop him from reaching the vault.",
        "Type": "Encounter",
        "Beginning": "Festival chaos allows LOGAN's team to advance.",
        "End": "Henchmen are repelled, leading to vault confrontation."
      },
      {
        "Title": "The Arcane Showdown",
        "Overview": "Inside the vault, a climactic confrontation unfolds. LOGAN reveals his mastery of dark magic, forcing the adventurers to confront him before he unlocks the necklace’s powers.",
        "Type": "Encounter",
        "Beginning": "Enter the vault for a final battle with LOGAN.",
        "End": "LOGAN's defeat secures the necklace, restoring peace."
      },
      {
        "Title": "The Aftermath",
        "Overview": "With LOGAN defeated and the necklace secured, the city breathes a sigh of relief. JUSTICE acknowledges the heroes' deeds, and ONYX commends their bravery.",
        "Type": "Social",
        "Beginning": "Daybreak brings relief to Eldergate.",
        "End": "Adventurers are celebrated, ensuring the city's safety."
      }
    ]
  }
}

Now, write the narrative summary.

**Antagonist Alias:**
NIGHTSHADE
**Available Aliases for Characters:**
AVERY, IRA, NILE, PEYTON, RORY, VAUGHN, BLAKE, HAVEN, QUILL, JULES
(Use only the provided aliases for characters. Do not create new ones. If an alias is already used, choose another from the list.)
(You should not need to use all aliases, only those relevant to your narrative.)

`,
    resultKey: "narrativeSummary",
    nextStep: 5,
  },

  //   {
  //     id: 100,
  //     title: "Generate Next",
  //     stateKey: "dramatisPersonae",
  //     renderConfig: {
  //       type: "button",
  //       buttonText: "Generate Next",
  //     },
  //     nextAction: "apiCall",
  //     prompt: (data) => `
  //     You are an expert RPG adventure designer. You are tasked with developing a vibrant and dynamic cast of characters.

  // **Adventure Context:**
  // - **Original User Input:** "${data.userInput}"
  // - **Selected Adventure Idea:**
  //   - **Title:** "${data.selectedIdea.Title}"
  //   - **Theme:** "${data.selectedIdea.Theme}"
  //   - **Setting:** "${data.selectedIdea.Setting}"

  // **Selected Antagonist:**
  //   - **Name**: "${data.selectedAntagonist.Name}"
  //   - **BasicDescription**: "${data.selectedAntagonist.BasicDescription}"
  //   - **InitialMotivation**: "${data.selectedAntagonist.InitialMotivation}"
  //   - **RoleInAdventure**: "${data.selectedAntagonist.RoleInAdventure}"
  //   - **AbilitiesAndResources**: "${data.selectedAntagonist.AbilitiesAndResources}"

  // Populate the **Dramatis Personae** section with the major characters (NPCs) who will play significant roles in the adventure, offering Game Masters essential information to bring these characters to life.

  // 1. **Antagonist Recap:**
  // - Include the Antagonist in your response.
  // - Follow the same response structure as the other NPCs.

  // 2. **Major NPCs:**
  // - List all major NPCs that players will encounter throughout the adventure.
  // - Each NPC should have a distinct personality, role, and motivation.

  // 3. **Details for Each NPC:**
  // - The 'Dramatis Personae' section should be especially detailed, providing for each character (fields marked with * are optional):
  //   - **Name**
  //   - **Race**
  //   - **Role**
  //   - **PhysicalDescription**: Detailed appearance, attire, and notable physical traits.
  //   - **Personality**: Core personality traits, behaviors, and mannerisms.
  //   - **Backstory**: Brief history that informs their motivations and current situation.
  //   - **GoalsAndMotivations**: What drives them within the context of the adventure.
  //   - **RoleInAdventure**: How they fit into the narrative and interact with the players.
  //   - **ActingNotes**: Guidance for the Game Master on how to portray the character, including speech patterns, body language, and any accents or catchphrases.
  //   - **ResourcesAndAbilities**: Non-mechanical description of their skills, knowledge, and any assets they can provide.
  //   - **IntegrationTips**: Suggest ways in which the NPC can be woven into different parts of the adventure. This could include dialogue cues, potential alliances or conflicts with the players, and how their story unfolds as the adventure progresses.
  //   - *OptionalFields* (Include if relevant):
  // 	- **AlliesAndContacts**
  // 	- **Secrets**

  // **Instructions:**

  // - Update the existing JSON by adding the "DramatisPersonae" key to the "AdventureModule" object.

  // **Example Output Format:**

  // {
  // "AdventureModule": {
  // "DramatisPersonae": [
  // {
  // "Name":"",
  // "Race":"",
  // "Role":"",
  // "PhysicalDescription":"",
  // "Personality":"",
  // "Backstory":"",
  // "GoalsAndMotivations":"",
  // "RoleInAdventure":"",
  // "ActingNotes":"",
  // "ResourcesAndAbilities":"",
  // "AlliesAndContacts":"",
  // "Secrets":""
  // },
  // ...
  // ]
  // }
  // }

  // Now, write the Dramatis Personae.
  // `,
  //     resultKey: "dramatisPersonae",
  //     nextStep: 200,
  //   },

  {
    id: 5,
    title: "Final Review and Export",
    stateKey: "finalReview",
    renderConfig: {
      type: "final",
      dataKey: "adventureModule",
      label: "Test",
    },
    // No nextStep as it's the end of the process
  },
];

export default steps;
