import React, { useState, useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Modal,
  TextField,
  Card,
  CardContent,
  Container,
  ListItem,
  CircularProgress,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import DragonChatInterface from "./DragonChatInterface";
import DragonCoilRuns from "./DragonCoilRuns";
import DragonFlows from "./DragonFlows";
import DragonFlowsVisual from "./DragonFlowsVisual";
import DragonPrompts from "./DragonPrompts";
import Playground from "./Playground";
import Admin from "./Admin";
import Metrics from "./Metrics";
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar } from "@mui/material";
import { getAwsUrl } from "./EnvHelper";
import JsonRenderer from "./JsonRenderer";
import useGetCollection from "./useGetCollection";
import CharacterCreation from "./CharacterCreation";
import AdventureCreator from "./AdventureCreator";
import StepsController from "./StepsController";

const App = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [drawerData, setDrawerData] = useState([]);
  const [pingResult, setPingResult] = useState(null);
  const [serverStatus, setServerStatus] = useState("yellow");

  const [chatHistory, setChatHistory] = useState([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    isAuthenticated,
    loginWithRedirect,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0();
  const { user, logout } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  useEffect(() => {
    const fetchDrawerData = async () => {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: "read:current_user email profile",
          },
        });
        const url = getAwsUrl("REACT_APP_DRAGONSCALES_ENDPOINT") + "/drawers";
        console.log("Fetching drawer data from:", url);
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setDrawerData(data.drawers);
      } catch (error) {
        console.error("Error fetching drawer data:", error);
      }
    };

    if (isAuthenticated) {
      fetchDrawerData();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    if (!modalOpen) {
      fetchPingData();
    }
  };

  const fetchPingData = async () => {
    try {
      const url = getAwsUrl("REACT_APP_DRAGONSCALES_ENDPOINT") + "/ping";
      const response = await fetch(url);
      const data = await response.json();
      setPingResult(data);

      if (data.message) {
        setServerStatus("green");
      } else {
        setServerStatus("red");
      }
    } catch (error) {
      console.error("Error fetching ping data:", error);
      setServerStatus("red");
    }
  };

  const {
    collectionData: flows,
    error: flowsError,
    isLoading: flowsIsLoading,
  } = useGetCollection("/flows");

  const handleSendMessage = async (flowName) => {
    const selectedFlow = flows.find((flow) => flow.name === flowName);
    if (!selectedFlow) return;

    let payload = {
      flow_id: selectedFlow.id,
      name: flowName,
      payload: {
        input: message || "",
        history: chatHistory,
      },
    };

    const newChatHistory = [
      ...chatHistory,
      { type: "USER", text: message, result: null },
    ];
    setChatHistory(newChatHistory);
    setMessage("");
    setLoading(true);

    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "read:current_user email profile",
      },
    });
    fetch(getAwsUrl("REACT_APP_DRAGONSCALES_ENDPOINT") + "/coil/execute", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        let resp =
          data && data.output && typeof data.output === "string"
            ? data.output
            : "";

        if (data.output && data.output.history) {
          delete data.output.history;
        }
        setError("");
        setChatHistory([
          ...newChatHistory,
          { type: flowName, text: resp, result: data },
        ]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setError(error.toString());
        setChatHistory([
          ...newChatHistory,
          {
            type: flowName,
            text: "ERROR",
            result: error.toString(),
          },
        ]);
        setLoading(false);
      });
  };

  const handleClearChat = () => {
    setChatHistory([]);
  };

  const drawer = (
    <div>
      <List>
        {drawerData.map((drawer, index) => (
          <ListItemButton key={index} component={Link} to={drawer.path}>
            <ListItemText primary={drawer.name} />
          </ListItemButton>
        ))}
      </List>
    </div>
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <AppBar position="fixed" style={{ zIndex: 1201 }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Dragon
          </Typography>
          {isAuthenticated && user && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body1" style={{ marginRight: 16 }}>
                {user.name}
              </Typography>
              <Avatar alt={user.name} src={user.picture} />
            </div>
          )}
          <IconButton color="inherit" onClick={toggleModal}>
            <SettingsIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          "& .MuiDrawer-paper": {
            top: "64px", // Offset the drawer from the top to account for the AppBar
          },
        }}
      >
        {drawer}
      </Drawer>
      <main style={{ flexGrow: 1, paddingTop: "64px", overflowY: "auto" }}>
        {isAuthenticated ? (
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  chatHistory={chatHistory}
                  loading={loading}
                  flowsIsLoading={flowsIsLoading}
                  error={error}
                  flowsError={flowsError}
                  handleSendMessage={handleSendMessage}
                  message={message}
                  setMessage={setMessage}
                  handleClearChat={handleClearChat}
                />
              }
            />
            <Route path="/dragonchat" element={<DragonChatInterface />} />
            <Route path="/coilruns" element={<DragonCoilRuns />} />
            <Route path="/flows" element={<DragonFlows />} />
            <Route path="/flowsvisual" element={<DragonFlowsVisual />} />
            <Route path="/prompts" element={<DragonPrompts />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/metrics" element={<Metrics />} />
            <Route path="/playground" element={<Playground />} />
            <Route path="/charactercreation" element={<CharacterCreation />} />
            <Route path="/adventurecreator" element={<AdventureCreator />} />
            <Route path="/steps" element={<StepsController />} />
          </Routes>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => loginWithRedirect()}
            >
              Log In
            </Button>
          </div>
        )}
      </main>
      <Modal
        open={modalOpen}
        onClose={toggleModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            backgroundColor: "white",
            padding: 16,
            boxShadow: 24,
          }}
        >
          <Typography variant="h6" id="simple-modal-title">
            Settings
          </Typography>
          <Typography variant="body2" id="simple-modal-description">
            Settings content goes here.
          </Typography>
          <div style={{ marginTop: 16 }}>
            <Typography variant="body1">
              Server Status:{" "}
              <span
                style={{
                  color:
                    serverStatus === "green"
                      ? "green"
                      : serverStatus === "red"
                      ? "red"
                      : "yellow",
                }}
              >
                {serverStatus}
              </span>
            </Typography>
            <Typography variant="body1">
              Ping URL: {getAwsUrl("REACT_APP_DRAGONSCALES_ENDPOINT") + "/ping"}
            </Typography>
            {pingResult && <JsonRenderer data={pingResult} />}
          </div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => logout({ returnTo: window.location.origin })}
            style={{ marginTop: 16 }}
          >
            Log Out
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

const Home = ({
  chatHistory,
  loading,
  flowsIsLoading,
  error,
  flowsError,
  handleSendMessage,
  message,
  setMessage,
  handleClearChat,
}) => (
  <Container>
    <AppBar position="sticky">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Dragon Chat
        </Typography>
        <Button color="inherit" onClick={handleClearChat}>
          Clear
        </Button>
      </Toolbar>
    </AppBar>
    <Box
      sx={{
        flexGrow: 1,
        overflowY: "auto",
        padding: 2,
        height: "70vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          padding: 2,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <List sx={{ maxHeight: "100%", overflow: "auto" }}>
          {chatHistory.map((chat, index) => (
            <ListItem
              key={index}
              sx={{
                justifyContent:
                  chat.type === "USER" ? "flex-end" : "flex-start",
                width: "100%",
              }}
            >
              <Card
                sx={{
                  width: "fit-content",
                  maxWidth: "80%",
                  marginBottom: 2,
                  backgroundColor: chat.type === "USER" ? "#e0f7fa" : "#f1f1f1",
                  alignSelf: chat.type === "USER" ? "flex-end" : "flex-start",
                }}
              >
                <CardContent>
                  {chat.text && <Typography>{chat.text}</Typography>}
                  {!chat.text && chat.result && (
                    <Box sx={{ width: "100%", marginTop: 2 }}>
                      {typeof chat.result === "string" ? (
                        <Typography>{chat.result}</Typography>
                      ) : (
                        <Box sx={{ marginTop: 2 }}>
                          <JsonRenderer data={chat.result} />
                        </Box>
                      )}
                    </Box>
                  )}
                </CardContent>
              </Card>
            </ListItem>
          ))}
          {(loading || flowsIsLoading) && (
            <ListItem sx={{ justifyContent: "center", width: "100%" }}>
              <CircularProgress />
            </ListItem>
          )}
        </List>
      </Box>
      {error && <Typography color="error">{error}</Typography>}
      {flowsError && <Typography color="error">{flowsError}</Typography>}
    </Box>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: 2,
        borderTop: "1px solid #ccc",
      }}
    >
      <Button
        variant="contained"
        color="secondary"
        onClick={() => handleSendMessage("Image")}
        sx={{ marginRight: 2 }}
      >
        Image
      </Button>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Type a message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            handleSendMessage("Plain");
          }
        }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleSendMessage("Plain")}
        sx={{ marginLeft: 2 }}
      >
        Run
      </Button>
    </Box>
  </Container>
);

export default App;
